import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HpHeader = _resolveComponent("HpHeader")!
  const _component_Process = _resolveComponent("Process")!
  const _component_Matter = _resolveComponent("Matter")!
  const _component_FeaturedClients = _resolveComponent("FeaturedClients")!
  const _component_Hello = _resolveComponent("Hello")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_HpHeader),
    _createVNode(_component_Process),
    _createVNode(_component_Matter),
    _createVNode(_component_FeaturedClients),
    _createVNode(_component_Hello),
    _createVNode(_component_Footer)
  ], 64))
}