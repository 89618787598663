
import { defineComponent } from "vue";
import HpHeader from "../components/HomePgHeader.vue";
import FeaturedClients from "../components/FeaturedClients.vue";
import Services from "../components/Services.vue";
import Matter from "../components/Matter.vue";
import Process from "../components/Process.vue";
import Footer from "../components/Footer.vue";
import MobileDemo from "../components/MobileDemo.vue";
import Hello from "../components/Hello.vue";

export default defineComponent({
  name: "Team",
  components: {
    // HpHeader,
    Hello,
    Footer,
  },
});
