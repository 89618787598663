
import { defineComponent } from "vue";
import { MDBInput } from "mdb-vue-ui-kit";
import emailjs from "emailjs-com";

export default defineComponent({
  name: "Hello",
  data() {
    return {
      formsInfo: { fname: "", lname: "", email: "", comment: "" },
    };
  },
  components: {
    MDBInput,
  },
  methods: {
    resetfunction() {
      (this.formsInfo.fname = ""),
        (this.formsInfo.lname = ""),
        (this.formsInfo.email = ""),
        (this.formsInfo.comment = "");
    },
    SubmitrHandler() {
      emailjs.send(
        "service_cejfowa",
        "template_g0whw95",
        this.formsInfo,
        "user_3JRRpFCM644luaHX0hLe4"
      );
      this.resetfunction();
    },
  },
});
